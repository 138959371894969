var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _vm._m(0),
    _c("div", { staticClass: "page-content container-fluid" }, [
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-12" }, [
          _c("div", { staticClass: "material-card card" }, [
            _c("div", { staticClass: "card-body" }, [
              _c("div", { staticClass: "panel panel-warning" }, [
                _c("div", { staticClass: "panel-heading" }, [
                  _c(
                    "div",
                    {
                      staticClass: "row",
                      staticStyle: { "margin-bottom": "-14px" },
                    },
                    [
                      _c("div", { staticClass: "col-sm-12" }, [
                        _vm._m(1),
                        _c("div", { staticClass: "row" }, [
                          _c(
                            "div",
                            {
                              staticClass:
                                "col-lg-12 col-md-12 col-sm-12 col-xs-12",
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "panel price panel-green" },
                                [
                                  _vm._m(2),
                                  _vm._m(3),
                                  _vm._m(4),
                                  _c(
                                    "div",
                                    { staticClass: "text-center" },
                                    [
                                      _c(
                                        "vs-button",
                                        {
                                          staticClass: "mb-4 mt-4",
                                          attrs: {
                                            size: "large",
                                            to: "/contrato-licenca-uso",
                                          },
                                        },
                                        [_vm._v("CONTINUAR!")]
                                      ),
                                    ],
                                    1
                                  ),
                                ]
                              ),
                            ]
                          ),
                        ]),
                      ]),
                    ]
                  ),
                ]),
              ]),
            ]),
          ]),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      {
        staticClass: "page-breadcrumb",
        staticStyle: { "background-color": "black" },
      },
      [
        _c("div", { staticClass: "row" }, [
          _c(
            "div",
            { staticClass: "col-lg-3 col-md-4 col-xs-12 align-self-center" },
            [
              _c("img", {
                staticClass: "mx-auto mb-0",
                attrs: { src: "/logo.png", alt: "SIGCFC" },
              }),
              _c(
                "h5",
                {
                  staticClass: "font-medium text-uppercase mb-2",
                  staticStyle: { color: "white" },
                },
                [_vm._v("Proposta Comercial SIGCFC")]
              ),
            ]
          ),
        ]),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-lg-12 text-center" }, [
        _c("h2", [
          _c("span", { staticClass: "ion-minus" }),
          _vm._v("Proposta Comercial"),
          _c("span", { staticClass: "ion-minus" }),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "panel-heading arrow_box text-center" }, [
      _c("h3", [_vm._v("Mensal")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "panel-body text-center" }, [
      _c("p", { staticClass: "lead", staticStyle: { "font-size": "40px" } }, [
        _c("strong", [_vm._v("R$ 100,00")]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "ul",
      { staticClass: "list-group list-group-flush text-center" },
      [
        _c("li", { staticClass: "list-group-item" }, [
          _c("i", { staticClass: "icon-ok text-success" }),
          _vm._v(" Gestão Total de Alunos"),
        ]),
        _c("li", { staticClass: "list-group-item" }, [
          _c("i", { staticClass: "icon-ok text-success" }),
          _vm._v(" Geração de Contratos"),
        ]),
        _c("li", { staticClass: "list-group-item" }, [
          _c("i", { staticClass: "icon-ok text-success" }),
          _vm._v(" Modelos de Documentos"),
        ]),
        _c("li", { staticClass: "list-group-item" }, [
          _c("i", { staticClass: "icon-ok text-success" }),
          _vm._v(" Agendas de Exames"),
        ]),
        _c("li", { staticClass: "list-group-item" }, [
          _c("i", { staticClass: "icon-ok text-success" }),
          _vm._v(" Agendas de Aulas"),
        ]),
        _c("li", { staticClass: "list-group-item" }, [
          _c("i", { staticClass: "icon-ok text-success" }),
          _vm._v(" Lançamento de KM"),
        ]),
        _c("li", { staticClass: "list-group-item" }, [
          _c("i", { staticClass: "icon-ok text-success" }),
          _vm._v(" Controle de Frequências"),
        ]),
        _c("li", { staticClass: "list-group-item" }, [
          _c("i", { staticClass: "icon-ok text-success" }),
          _vm._v(" Controle de Ordens de serviço"),
        ]),
        _c("li", { staticClass: "list-group-item" }, [
          _c("i", { staticClass: "icon-ok text-success" }),
          _vm._v(" Controle de Fluxo de Caixa"),
        ]),
        _c("li", { staticClass: "list-group-item" }, [
          _c("i", { staticClass: "icon-ok text-success" }),
          _vm._v(" Plano de Contas"),
        ]),
        _c("li", { staticClass: "list-group-item" }, [
          _c("i", { staticClass: "icon-ok text-success" }),
          _vm._v(" Chat Interno"),
        ]),
        _c("li", { staticClass: "list-group-item" }, [
          _c("i", { staticClass: "icon-ok text-success" }),
          _vm._v(" Controle de Contas"),
        ]),
        _c("li", { staticClass: "list-group-item" }, [
          _c("i", { staticClass: "icon-ok text-success" }),
          _vm._v(" Boleto Bancário"),
        ]),
        _c("li", { staticClass: "list-group-item" }, [
          _c("i", { staticClass: "icon-ok text-success" }),
          _vm._v(" Relatórios Completos"),
        ]),
        _c("li", { staticClass: "list-group-item" }, [
          _c("i", { staticClass: "icon-ok text-success" }),
          _vm._v(" Cadastro de Turmas"),
        ]),
        _c("li", { staticClass: "list-group-item" }, [
          _c("i", { staticClass: "icon-ok text-success" }),
          _vm._v(" Cadastro de Disciplinas"),
        ]),
        _c("li", { staticClass: "list-group-item" }, [
          _c("i", { staticClass: "icon-ok text-success" }),
          _vm._v(" Cadastro de Cursos"),
        ]),
        _c("li", { staticClass: "list-group-item" }, [
          _c("i", { staticClass: "icon-ok text-success" }),
          _vm._v(" Controle de Veículos"),
        ]),
        _c("li", { staticClass: "list-group-item" }, [
          _c("i", { staticClass: "icon-ok text-success" }),
          _vm._v(" Controle de Funcionários"),
        ]),
        _c("li", { staticClass: "list-group-item" }, [
          _c("i", { staticClass: "icon-ok text-success" }),
          _vm._v(" Controle de Usuários"),
        ]),
        _c("li", { staticClass: "list-group-item" }, [
          _c("i", { staticClass: "icon-ok text-success" }),
          _vm._v(" Envio de Mala Direta"),
        ]),
        _c("li", { staticClass: "list-group-item" }, [
          _c("i", { staticClass: "icon-ok text-success" }),
          _vm._v(" Auditoria Completa"),
        ]),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }